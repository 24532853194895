import React, { memo, useCallback } from 'react'
import {
  Box,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import GetCompanyIconElement from '../../components/reusable/getCompanyIcon'
import { useHistory, useLocation } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import limitChars from '../../utils/limitChars'
import { getCurrencyCodeBySign } from '../../utils/general'

const useStyles = makeStyles((theme) => ({
  align: { display: 'flex', alignItems: 'center' },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  btn: {
    borderRadius: theme.spacing(3),
    fontSize: '12px',
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    padding: '4px 16px',
  },
  weight: {
    fontWeight: 600 || theme.typography.fontWeightMedium,
  },
  letterspacing: {
    fontWeight: 600 || theme.typography.fontWeightBold,
    letterSpacing: 0.6,
  },
  imgContainer: {
    '& img': {
      marginLeft: '-10px',
    },
  },
  invalidDName: {
    backgroundColor: '#fff5e0',
    padding: '4px 5px',
    borderRadius: '3px',
  },
}))

const FlightSelectedTrip = ({ minimumPrice }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const { i18n } = useTranslation()
  const { outgoing, isLoading, selectedMulti } = useSelector(
    (state) => state.flights
  )
  const { currency } = useSelector((state) => state.auth)
  const onChangeOrder = () => history.goBack()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const totalPrice = minimumPrice
  const currencyCode = getCurrencyCodeBySign(currency)
  /*
  const totalPrice = outgoing
    ? outgoing.prices.map((price) => price.amount).reduce((a, b) => a + b, 0)
    : []
  */

  const showOutgoing =
    pathname.includes('returning') || pathname.includes('multi')
  const items = outgoing ? [outgoing] : [...Object.values(selectedMulti)]
  const isMulti = pathname.includes('multi')

  const renderFlights = useCallback(() => {
    return items.map((item, idx) => (
      <>
        <Box pt={2} pb={1}>
          <Box className={classes.spaceBetween}>
            {!isMulti && (
              <>
                <Typography variant="body2" className={classes.letterspacing}>
                  {i18n.t('filter interest')}
                </Typography>

                <Button
                  className={classes.btn}
                  variant="text"
                  color="primary"
                  onClick={onChangeOrder}
                  disableElevation
                >
                  {i18n.t('filter change')}
                </Button>
              </>
            )}
          </Box>
          <Box pt={1} className={classes.align}>
            <Typography variant="body2">
              <Moment format="ddd D MMM">{item.date}</Moment>
            </Typography>
            <Box display="flex" pl={1}>
              <Typography variant="body2" className={classes.weight}>
                {item.departTime}
              </Typography>
              <Typography variant="body2" className={classes.weight}>
                –
              </Typography>
              <Typography variant="body2" className={classes.weight}>
                {item.arriveTime}
              </Typography>
            </Box>
          </Box>
          <Box pt={1} className={classes.align}>
            <Box>
              <Typography variant="body2">
                <span>
                  {limitChars(item.from.city)} ({item.from.shortName})
                </span>{' '}
                –{' '}
                <span>
                  {limitChars(item.to.city)} ({item.to.shortName})
                </span>
              </Typography>
            </Box>
          </Box>
          <Box pt={1} className={classes.align}>
            <Box className={classes.imgContainer}>
              <GetCompanyIconElement small={isMobile} company={item.company} />
            </Box>

            <Typography
              style={{ paddingLeft: '8px' }}
              variant="body2"
              className={classes.weight}
            >
              {item.formattedTravelTime}
            </Typography>

            <Box pl={1}>
              <Typography variant="body2">
                {item.via.length > 0
                  ? `${item.via.length - 1} ${t('changes')}`
                  : t('filter directly')}
              </Typography>
            </Box>
          </Box>
        </Box>
        {idx < items.length - 1 && (
          <Divider style={{ marginLeft: '0px', marginRight: '0px' }} />
        )}
      </>
    ))
  }, [items])

  return (
    <Box style={{ minHeight: 190 }}>
      <Box pb={1}>
        <Box className={classes.spaceBetween} pb={1} pt={0.5}>
          <Typography className={classes.weight}>
            {i18n.t('your trip')}
          </Typography>
          {outgoing && !isLoading && showOutgoing ? (
            <Typography className={classes.weight}>
              {totalPrice}
              {currencyCode}
            </Typography>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Divider />

      {isLoading ? (
        <Box
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        renderFlights()
      )}
    </Box>
  )
}

export default memo(FlightSelectedTrip)
