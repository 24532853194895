import React, {
  memo,
  useEffect,
  useCallback,
  useState,
  useMemo,
  Fragment,
} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Divider } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TicketLabel from './ticketLabel'
import MixedFareClasses from './mixedFareClasses'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import { calculateInitialPrices } from '../../../utils/flightGeneralFunctions'
import { useSelector } from 'react-redux'
import numberWithCommas from '../../../utils/numberWithCommas'
import Policy from '../../reusable/tags/policy'

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    background: 'white',
    margin: 0,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  disabled: {
    background: '0 !important',
  },
})(MuiAccordion)

const contentStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const AccordionSummary = withStyles({
  root: {
    transition: 0,
    padding: 0,
    marginBottom: -1,
    minHeight: 60,
    margin: 0,
    '&$expanded': {
      minHeight: 60,
    },
  },
  content: {
    ...contentStyle,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  disabled: {
    opacity: 1,
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0',
    paddingBottom: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  root: {
    '& strong': {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      '&:first-child': { marginLeft: 0 },
    },
  },
  dirLabel: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: '600',
    marginBottom: '10px',
  },
  ffLabel: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'italic',
    textTransform: 'capitalize',
  },
  fareLabel: {
    marginRight: '10px',
  },
  accordContainer: {
    background: '#eef8f8',
    borderRadius: theme.spacing(1),
    width: '100%',
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  ticketContentBlock: {
    ...contentStyle,
    margin: '12px 0',
  },
  textCenter: {
    textAlign: 'center',
  },
  mixedAccordContainer: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  normalText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  mediumText: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  infoBanner: {
    display: 'flex',
    paddingLeft: '30px',
    paddingRight: '10px',
    height: '50px',
    width: '100%',
    backgroundColor: '#FFF6CC',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '8px',
  },
  co2Banner: {
    padding: '16px 0px 16px 30px',
    width: '100%',
    backgroundColor: '#FFF6CC',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '8px',
  },
}))

const SelectTicketType = ({
  pricing,
  returning,
  outgoing,
  titlePrefix,
  onSelected,
  isMTKInbound = false,
}) => {
  const mixTabKey = 'mixed-class-ff'
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(0)
  const [selectedValue, setSelectedValue] = useState('')
  const { fareLoading } = useSelector((state) => state.flightDetails)
  const { user, currency } = useSelector((state) => state.auth)
  const flightState = useSelector((state) => state.flights)
  const { passengers } = flightState
  const initialPrices = useMemo(
    () => calculateInitialPrices(flightState),
    [flightState]
  )

  const outboundFlKeys = useMemo(() => {
    const key = !returning && !!isMTKInbound ? 'returnTrip' : 'trip'
    return outgoing.original[key].map((fl) => fl.uniqueInd)
  }, [outgoing, isMTKInbound, returning])

  const returnFlKeys = useMemo(() => {
    return returning
      ? returning.original.returnTrip.map((fl) => {
          return fl.uniqueInd
        })
      : null
  }, [returning])

  const { highlightRefundableTickets, highlightCo2Tickets } = user || {}

  let defaultFarePrice = 0

  if (!initialPrices.isMTK) {
    defaultFarePrice = initialPrices.outbound
  } else if (outgoing && !returning) {
    defaultFarePrice =
      outgoing.group === 'returnTrip'
        ? initialPrices.return
        : initialPrices.outbound
  }

  if (defaultFarePrice && passengers?.length)
    defaultFarePrice = Math.round(defaultFarePrice / passengers.length)

  const fareCount = pricing?.length ?? 0

  const setSelectedFlight = useCallback(
    (flight) => {
      setSelectedValue(flight)
      onSelected(flight && typeof flight === 'object' ? flight : null)
    },
    [onSelected]
  )

  useEffect(() => {
    let pric = pricing?.find((p) => !p.isMixFly)
    if (!pric) pric = pricing?.find((p) => !!p.isMixFly)
    // init
    if (pric) {
      setSelectedFlight(pric)
      //      if (!!pric.isMixFly) setExpanded(mixTabKey)
    }
  }, [pricing, setSelectedFlight])

  const getOutboundOptions = () => {
    if (fareCount <= 0 || !returning) {
      return null
    }

    const opts = {}
    pricing.forEach((ff) => {
      const labels = []
      const Keys = []

      outboundFlKeys.forEach((fk) => {
        const tmpLabel = ff.byFlights[fk]?.label || ff.byFlights[fk]?.ffName
        if (!labels.includes(tmpLabel)) labels.push(tmpLabel)
        Keys.push(ff.byFlights[fk].ffName)
      })
      if (Keys.length <= 0) return

      const optKey = Keys.join('::')

      if (typeof opts[optKey] == 'object' && opts[optKey]) {
        opts[optKey]['returnFF'].push({ ...ff })
        if (opts[optKey]['minPrice'] > ff.price)
          opts[optKey]['minPrice'] = ff.price
      } else {
        opts[optKey] = {
          label: labels.join(', '),
          value: optKey,
          returnFF: [{ ...ff }],
          minPrice: ff.price,
        }
      }
    })
    return opts
  }

  const handleMixedSelected = () => (flight) => {
    setSelectedFlight(flight)
  }

  const handleChange =
    (panel, flight = '') =>
    (_event, newExpanded) => {
      setExpanded(newExpanded ? panel : false)
      setSelectedFlight(flight)
    }

  const radioChange = (flight) => setSelectedValue(flight)
  let isMixedExists = false

  const singleMix = {}
  const ffToRender = []

  if (typeof pricing === 'object' && pricing.length) {
    pricing.forEach((flight) => {
      if (flight.isMixFly) {
        const keys = []
        outboundFlKeys.forEach((fk) => keys.push(flight.byFlights[fk].ffName))
        if (keys.length && !!returning) {
          const rtKeys = []
          returnFlKeys.forEach((fk) => rtKeys.push(flight.byFlights[fk].ffName))
          const mixKey = keys.join('::')
          const rtKey = rtKeys.join('::')
          if (rtKey !== mixKey) {
            if (!singleMix[mixKey]) singleMix[mixKey] = []
            singleMix[mixKey].push(flight)
          }
        }
        isMixedExists = true
        return null
      }
      ffToRender.push(flight)
    })

    for (let k in singleMix) {
      if (singleMix[k].length === 1) ffToRender.push(singleMix[k][0])
    }
  }

  const ticketClasses = {
    Y: 'economy class',
    M: 'economy class',
    W: 'economy premium class',
    C: 'business class',
    F: 'first class',
  }

  const getDynamicLabel = (ff) => {
    const label = t(ticketClasses?.[ff?.cabin] || 'dynamic fare')
    const basis = ff.ffFareBasis

    return `${label} (${basis})`
  }

  // Added conditional, application would throw if this was not in place // Simon
  const renderPriceAlternatives = ffToRender?.map((flight, i) => {
    const { price, totalPrice } = flight
    const families = {}

    if (!!outgoing && !!returning) {
      for (let fk in flight.byFlights) {
        const tmpFF = flight.byFlights[fk]
        if (outboundFlKeys.includes(fk) && !families.outbound) {
          families.outbound = tmpFF
        }

        if (
          !!returnFlKeys?.length &&
          returnFlKeys.includes(fk) &&
          !families.return &&
          flight.isMixFly
        ) {
          families.return = tmpFF
        }
      }
    } else {
      families.outbound = flight.ff[0]
    }

    let fareLabel = families.outbound?.isDynamic
      ? getDynamicLabel(families?.outbound)
      : capitalizeFirstLetter(families?.outbound?.label || '')
    if (families?.return) {
      const rLabelText = families.return?.isDynamic
        ? getDynamicLabel(families.return)
        : capitalizeFirstLetter(families.return.label || families.return.ffName)
      fareLabel += ` + ${rLabelText}`
    }

    const policyStyle = {
      display: isMobile ? 'flex' : 'inline-flex',
    }
    const labelNode = (
      <Fragment>
        <span className={classes.fareLabel}>{fareLabel}</span>
        <Policy
          rootStyles={policyStyle}
          deal={flight.isDealFare}
          corporate={flight.isCorporateFare}
        />
      </Fragment>
    )
    return (
      <Box key={i}>
        <Accordion
          key={i}
          square
          expanded={expanded === i}
          onChange={expanded !== i ? handleChange(i, flight) : null}
        >
          <AccordionSummary aria-controls="panel1d-content">
            <FormControlLabel
              value={selectedValue}
              onChange={radioChange}
              control={
                <Radio
                  color="primary"
                  checked={expanded === i}
                  value={selectedValue}
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              }
              label={labelNode}
              labelPlacement="end"
              style={{ marginRight: '0px' }}
            />
            <Box className={classes.align}>
              <Typography>{currency}</Typography>
              <Typography className={classes.weight}>
                {numberWithCommas(totalPrice)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={'100%'}>
              <Box p={3} pt={2} pb={3} className={classes.accordContainer}>
                {!!families.return && (
                  <Fragment>
                    <Typography className={classes.dirLabel} variant="h6">
                      {' '}
                      {t('outbound benefits')}{' '}
                    </Typography>
                    <Typography className={classes.ffLabel} variant="h6">
                      {(
                        families.outbound.label || families.outbound.ffName
                      ).toLowerCase()}
                    </Typography>
                  </Fragment>
                )}
                <TicketLabel labels={families.outbound} />
              </Box>
              {!!families.return && (
                <Box p={3} pt={2} pb={3} className={classes.accordContainer}>
                  <Typography className={classes.dirLabel} variant="h6">
                    {' '}
                    {t('return trip benefits')}{' '}
                  </Typography>
                  <Typography className={classes.ffLabel} variant="h6">
                    {(
                      families.return.label || families.return.ffName
                    ).toLowerCase()}
                  </Typography>
                  <TicketLabel labels={families.return} />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Box>
    )
  })

  const renderDefault = () => {
    return (
      <Box className={classes.ticketContentBlock}>
        <FormControlLabel
          onClick={(e) => e.stopPropagation()}
          control={<Radio color="primary" checked={true} />}
          label={t('detail standart')}
          labelPlacement="end"
        />

        {!!defaultFarePrice && (
          <Box className={classes.align}>
            <Typography>{currency}</Typography>
            <Typography className={classes.weight}>
              {numberWithCommas(defaultFarePrice)}
            </Typography>
            <Typography> / {t('one travel')}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  const renderMain = () => {
    const mixOpts = getOutboundOptions()
    const mixedClassSelected =
      expanded === mixTabKey &&
      typeof selectedValue === 'object' &&
      selectedValue
    const flByNums = {}
    //console.log('selectedValue selectedValue selectedValue selectedValue', selectedValue);
    return (
      <Box pb={4}>
        {renderPriceAlternatives}
        {isMixedExists && mixOpts && (
          <Box key={mixTabKey}>
            <Accordion
              key={mixTabKey}
              square
              expanded={expanded === mixTabKey}
              onChange={expanded !== mixTabKey ? handleChange(mixTabKey) : null}
            >
              <AccordionSummary aria-controls="panel1d-content">
                <FormControlLabel
                  value={selectedValue}
                  control={
                    <Radio
                      color="primary"
                      checked={expanded === mixTabKey}
                      value={selectedValue}
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  }
                  label={t('mixed classes')}
                  labelPlacement="end"
                />

                {!mixedClassSelected && (
                  <Typography> {t('mixed no price')} </Typography>
                )}
                {mixedClassSelected && (
                  <Box className={classes.align}>
                    <Typography>{currency}</Typography>
                    <Typography className={classes.weight}>
                      {numberWithCommas(selectedValue['price'])}
                    </Typography>
                    <Typography> / {t('traveler')}</Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box p={2} className={classes.mixedAccordContainer}>
                  {expanded === mixTabKey && (
                    <MixedFareClasses
                      onClassSelected={handleMixedSelected()}
                      selected={selectedValue}
                      options={mixOpts}
                      outboundFlKeys={outboundFlKeys}
                      returnFlKeys={returnFlKeys}
                      flightsByNums={flByNums}
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Box>
        )}
      </Box>
    )
  }

  const title = titlePrefix ? (
    <span className={classes.normalText}>
      <span className={classes.weight}>{titlePrefix}</span> {t('select class')}
    </span>
  ) : (
    <span className={classes.weight}>{t('detail page')}</span>
  )
  return (
    <Box pt={2} paddingX={isMobile ? 2 : 4} pb={4}>
      <Box pb={3} pt={2}>
        <Typography variant="h5" className={classes.weight}>
          {title}
        </Typography>
      </Box>
      {highlightCo2Tickets && (
        <Box
          className={classes.co2Banner}
          mb={2}
          dangerouslySetInnerHTML={{
            __html: t('always use co2 friendly tickets'),
          }}
        />
      )}
      {highlightRefundableTickets && (
        <Box className={classes.infoBanner} mb={2}>
          <Box>
            <span className={classes.mediumText}>
              {user?.companyName} {t('recommends')}
            </span>
            &nbsp;{t('that you choose')}&nbsp;
            <span className={classes.mediumText}>{t('a flexible ticket')}</span>
            .
          </Box>
        </Box>
      )}
      <Divider />
      {fareLoading && (
        <Box py={3} className={classes.textCenter}>
          <CircularProgress />
        </Box>
      )}
      {!fareLoading && fareCount > 0 && renderMain()}
      {!fareLoading && fareCount <= 0 && renderDefault()}
    </Box>
  )
}

export default memo(SelectTicketType)
