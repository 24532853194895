import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const FlightMiddleStepMobile = ({ flight, isOutgoing, totalPrice }) => {
  const classes = useStyles()
  const { guests } = useParams()
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)

  const text = isOutgoing ? (
    <Typography align="right" className={classes.bold}>
      { currency } {totalPrice}
    </Typography>
  ) : (
    <Typography align="right">{guests} {t('detail adults')}</Typography>
  )

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography>
          {isOutgoing ? t('detail outbound') : t('detail return')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography align="center">
          <Moment format="ddd D MMM">{flight.date}</Moment>
        </Typography>
      </Grid>
      <Grid item xs>
        {text}
      </Grid>
    </Grid>
  )
}

export default memo(FlightMiddleStepMobile)
