import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  Typography,
  Button,
  Box,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import Moment from 'react-moment'
import { toHoursAndMinutes } from '../../../../utils/formatDates'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { formatTrainDuration } from '../../../../utils/formatDurations'
import GetCompanyIcon from '../../../../components/reusable/getCompanyIcon'
import { useTranslation } from 'react-i18next'
import { getCurrencyCodeBySign } from '../../../../utils/general'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 220,
  },
  align: { display: 'flex', alignItems: 'center' },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  destination: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  btn: {
    borderRadius: theme.spacing(3),
    fontSize: '12px',
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    padding: '4px 16px',
  },
  icon: {
    height: '40px',
    objectFit: 'cover',
  },
  weight: {
    fontWeight: 600,
  },
  letterspacing: {
    fontWeight: 600,
    letterSpacing: 0.6,
  },
}))

const SelectedTrains = ({ returning }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { selectedOutGoingTrip, isLoading } = useSelector(
    (state) => state.train
  )
  const { currency } = useSelector(state => state.auth)
  const scheduleSolution = selectedOutGoingTrip?.train?.scheduleSolution
  const Amount = selectedOutGoingTrip?.pricing?.price?.Amount
  const departureTime = toHoursAndMinutes(scheduleSolution?.railstart?.dateTime)
  const arrival = toHoursAndMinutes(scheduleSolution?.railend?.dateTime)
  const railStartDestination = scheduleSolution?.railstart?.locationName
  const railEndDestination = scheduleSolution?.railend?.locationName
  const trip = `${railStartDestination?.toLowerCase()} -
  ${railEndDestination?.toLowerCase()}`

  const onGoBack = () => history.goBack()

  const iconString = scheduleSolution?.serverProviderCode

  const howManyChanges = selectedOutGoingTrip
    ? selectedOutGoingTrip.train.segments.length
    : ''
  const currencyCode = getCurrencyCodeBySign(currency)
  const Shouldshow = returning

  return (
    <Box className={classes.container} p={2}>
      <Box className={classes.spaceBetween} pb={2} pt={0.5}>
        <Typography className={classes.weight}>{t('your trip')}</Typography>
        {Amount ? (
          <Typography className={classes.weight}>
            {Amount && Shouldshow && !isLoading ? `${Amount}${currencyCode}` : ''}
          </Typography>
        ) : (
          ''
        )}
      </Box>
      <Divider />
      {isLoading && returning ? (
        <Box
          height="143px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box pt={2} pb={1} className={classes.spaceBetween}>
            <Typography
              className={classes.weight}
              variant="body2"
              style={{ textTransform: 'uppercase' }}
            >
              {!selectedOutGoingTrip
                ? t('select to the left')
                : t('chosen trip')}
            </Typography>
            {selectedOutGoingTrip && Shouldshow ? (
              <Button
                color="primary"
                variant="text"
                className={classes.btn}
                onClick={onGoBack}
                disableElevation
              >
                {t('filter change')}
              </Button>
            ) : (
              ''
            )}
          </Box>
          {/* Bottom */}
          {selectedOutGoingTrip && Shouldshow && (
            <Box>
              <Typography style={{ fontSize: '14px' }}>
                <Moment format="ddd D MMM">
                  {scheduleSolution?.railstart?.dateTime}
                </Moment>{' '}
                {''}
                <span className={classes.weight}>
                  {departureTime}–{arrival}
                </span>
              </Typography>
              <Box pt={1}>
                <Typography className={classes.destination}>{trip}</Typography>
              </Box>
              <Box className={classes.align} pt={1}>
                <Box mr={1}>
                  <GetCompanyIcon company={iconString} height={14} width={20} />
                </Box>
                <Typography
                  className={classes.weight}
                  style={{ fontSize: '14px' }}
                >
                  {formatTrainDuration(scheduleSolution.Duration)}
                </Typography>
                <Box pl={1}>
                  <Typography style={{ fontSize: '14px' }}>
                    {howManyChanges === 1 ? 0 : howManyChanges} {t('changes')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default memo(SelectedTrains)
