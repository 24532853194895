import _ from 'lodash'
import React, { memo, useMemo } from 'react'
import { Box } from '@material-ui/core'
import numberWithCommas from '../../utils/numberWithCommas'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  priceRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: theme.palette.common.black,
    marginBottom: '13px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  totalRow: {
    fontWeight: theme.typography.fontWeightBold,
  },
  name: {
    flex: 1,
  },
  divider: {
    height: '1px',
    backgroundColor: theme.palette.border,
    width: '100%',
  },
}))

const TotalPriceBlock = ({ data, addedAddons }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { currency } = useSelector((state) => state.auth)
  const total = useMemo(() => {
    let res = data?.tarif?.estimatedTotal || 0
    const extras = data?.extras?.length ? data?.extras : []

    extras.forEach((e) => {
      res += Number(e?.price) || 0
    })

    addedAddons?.forEach((addOn) => {
      res += Number(addOn?.price) || 0
    })
    return res
  }, [data, addedAddons])

  const priceLines = useMemo(() => {
    return [...(!addedAddons?.length ? [] : addedAddons)]
  }, [data, addedAddons])

  return (
    <Box py={3} px={2}>
      <Box className={classes.priceRow}>
        <Box className={classes.name}>{t('car rental price')}</Box>
        <Box>
          {currency} {numberWithCommas(Math.round(data.tarif.estimatedTotal))}
        </Box>
      </Box>
      {!!data.extras.length &&
        data.extras.map((e) => (
          <Box className={classes.priceRow}>
            <Box className={classes.name}>
              {_.capitalize((e?.name || '').toLowerCase())}
            </Box>
            <Box>
              {currency} {numberWithCommas(Math.round(e?.price || 0))}
            </Box>
          </Box>
        ))}
      {priceLines?.map((addOn) => (
        <>
          <Box className={classes.priceRow}>
            <Box className={classes.name}>
              {_.capitalize((addOn?.name || '').toLowerCase())}
            </Box>
            <Box>
              {currency} {numberWithCommas(Math.round(addOn?.price || 0))}
            </Box>
          </Box>
        </>
      ))}
      <Box mb={'13px'} className={classes.divider} />
      <Box className={`${classes.priceRow} ${classes.totalRow}`}>
        <Box className={classes.name}>{t('detail total')}</Box>
        <Box>
          {currency} {numberWithCommas(Math.round(total))}
        </Box>
      </Box>
    </Box>
  )
}

export default memo(TotalPriceBlock)
