import React, {useCallback, useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { useSliderStyles } from '../flights/filters/sliderStyles'
import numberWithCommas from '../../utils/numberWithCommas';
import { useSelector } from 'react-redux'
import { getCurrencyCodeBySign } from '../../utils/general'

const RangeSlider = ({title = '', min, max, highest, lowest = 0, callBack}) => {
  const classes = useSliderStyles()
  const [val, setVal] = useState([0, 0])
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = getCurrencyCodeBySign(currency)
  const onChange = useCallback((e, v) => {
    setVal(v)
  }, [])
  const applyChange = (e, v) => {
    callBack(v)
  }
  useEffect(() => {
    setVal([min, max])
  }, [min, max])
  const rHighest = Math.round(highest)
  const rLowest = Math.round(lowest || 0)
  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
      <Box padding={'0 7px'}>
        <Slider
          value={val}
          onChange={onChange}
          onChangeCommitted={applyChange}
          valueLabelDisplay="on"
          step={10}
          valueLabelFormat={(val) => `${numberWithCommas(val || 0)}${currencyCode}`}
          color="primary"
          max={rHighest}
          min={rLowest}
          classes={{root: `${classes.root} medium-label`}}
        />
      </Box>
    </>
  )
}

export default RangeSlider
