import React, { memo, useEffect } from 'react'
import _ from 'lodash'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getFFBags } from '../../../utils/flightGeneralFunctions'
import MenuItem from '@material-ui/core/MenuItem'
import numberWithCommas from '../../../utils/numberWithCommas'
import InputBase from '@material-ui/core/InputBase'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import isIframed from '../../../utils/isIframed'

export const fieldBorderStyles = {
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  border: '1px solid #e6e6e6',
}

export const useStyles = makeStyles((theme) => ({
  borderRadius: {
    marginTop: 0,
    borderRadius: '6px',
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#333',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  menuItemFlex: {
    display: 'flex',

    '& .price-label': {
      fontWeight: '500',
    },
  },
  flex1: {
    flex: 1,
  },
  formControl: {
    margin: 0,
    padding: 0,
    width: '100%',

    '& .MuiInputLabel-filled': {
      transform: 'translate(12px, 44px) scale(1)',
      color: theme.typography.gray,

      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, 5px) scale(1)',
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    backgroundColor: `${theme.palette.lightGreen} `,
    borderRadius: theme.spacing(1 / 2),
    '& span': {
      fontSize: '14px',
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
}))

const FareFamilyInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    'box-sizing': 'border-box',
    height: '55px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.border,
    fontSize: 16,
    color: theme.typography.gray,
    fontWeight: '500',
    padding: '18px 26px 18px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '& .price-label': {
      marginLeft: '5px',
    },
  },
}))(InputBase)

const SelectBagageItem = ({
  userId,
  fareFamily,
  bags,
  selectedBag,
  onChange,
  title
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { standAloneLoading } = useSelector((state) => state.flightDetails)
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'
  const selectedVal = selectedBag?.serviceCode ?? ''

  //execute only if bags changed!
  useEffect(() => {
    if (!selectedBag) return
    if (
      !selectedBag?.serviceCode ||
      !selectedBag?.price ||
      !selectedBag?.services ||
      !bags
    ) {
      onChange(userId, null)
      return
    }
    const foundVal = _.find(bags, { serviceCode: selectedBag.serviceCode })

    if (!foundVal) {
      onChange(userId, null)
      return
    }

    onChange(userId, foundVal)
  }, [bags, onChange])

  const handleChange = (event) => {
    const bag = _.find(bags, { serviceCode: event.target.value })
    onChange(userId, !bag ? null : bag)
  }

  const opts = bags.map((bag, key) => {
    const pt = bag?.pricingType || 'PPR'
    const bgCnt = bag.count
    const dscrTxt = bag.description
    const descr = `${(pt !== 'PPK' && bgCnt > 1) ? bgCnt : '' } ${t(dscrTxt, pt === 'PPK' ? {w: bag.w} : {})}`
    return (
        isIframed
            ? <option
                key={key}
                value={bag.serviceCode}
            >
              {descr} {numberWithCommas(bag.price)}{currencyCode}
            </option>
            : <MenuItem
                className={classes.menuItemFlex}
                key={key}
                value={bag.serviceCode}
            >
              <span className={classes.flex1}>{descr}</span>
              <span className={'price-label'}>{numberWithCommas(bag.price)}{currencyCode}</span>
            </MenuItem>
    )
  })

  return (
    <Box>
      {!standAloneLoading && opts.length > 0 && (
        <Box pt={3}>
          <Typography style={{ marginBottom: '8px' }}>{title ||t('add luggage')}</Typography>
          <FormControl variant="filled" className={classes.formControl}>
            <Select
              native={isIframed}
              input={<FareFamilyInput />}
              value={selectedVal}
              onChange={handleChange}
              className={!isIframed && classes.borderRadius}
              IconComponent={ExpandMoreIcon}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              displayEmpty
            >
              {
                isIframed
                  ? (
                    <option style={{ color: 'silver' }} value="">
                      {t('select')}...
                    </option>
                  ) : (
                    <MenuItem style={{ color: 'silver' }} value="">
                      <span style={{ color: '#999999', fontWeight: '500' }}>
                        {t('select')}...
                      </span>
                    </MenuItem>
                  )
              }
              {opts}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  )
}

export default memo(SelectBagageItem)
