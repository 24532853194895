import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import numberWithCommas from '../../../../../utils/numberWithCommas'

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  price: {
    color: theme.palette.dimGray,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const CheckoutType = () => {
  const checkout = useSelector((state) => state.checkout)
  const { t } = useTranslation()
  const classes = useStyles()
  const { currency } = useSelector((state) => state.auth)

  return (
    <Box>
      <Typography align="center" className={classes.weight}>
        {t('breadcrumbs pay')}
      </Typography>
      <Typography className={classes.price} align="center" variant="caption">
        {t('payment total total')} {numberWithCommas(checkout.sum)} {currency}
      </Typography>
    </Box>
  )
}

export default memo(CheckoutType)
