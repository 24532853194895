import React, { memo, useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next'
import { getChargeableSeatAmount } from '../../../../../utils/general'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const MobileAccomodationHeader = ({ index, train }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [chargeableSeatAmount, setChargeableSeatAmount] = useState(0)
  const { travellers: searchTravellers } = useSelector((state) => state.search.train)
  const { chargeableSeats } = useSelector((state) => state.train)
  const travellers = searchTravellers.length
  const { Amount, CurrencyCode } = train?.pricing?.price
  const { railidentifier } = train?.train?.segments?.[0]

  useEffect(() => {
    const amount = getChargeableSeatAmount(chargeableSeats, railidentifier)
    setChargeableSeatAmount(amount)
  }, [chargeableSeats])

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.bold}>
        {index === 0 ? t('outbound title') : t('inbound title')}
      </Typography>
      <Box className={classes.align}>
        <Typography>{travellers}</Typography>
        <PersonIcon fontSize="small" />
        <Typography variant="h5" className={classes.bold}>
          <span style={{ fontSize: '16px' }}>{CurrencyCode} </span>
          {chargeableSeatAmount ? Amount + chargeableSeatAmount : Amount}
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(MobileAccomodationHeader)
