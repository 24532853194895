import React, {Fragment, memo, useCallback, useEffect, useState} from 'react'
import ModalHook from '../modals/reusable/modalHook'
import CloseIcon from '@material-ui/icons/Close';
import { setCarExtras} from '../../store/cars/carAction'
import {useDispatch, useSelector} from 'react-redux'
import {Box, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import numberWithCommas from '../../utils/numberWithCommas'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 24px'
  },
  noExtras: {
    display: 'flex',
    height: '300px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '34px',
    color: theme.palette.common.black
  },
  headerBlock: {
    paddingBottom: '24px',
    marginBottom: '18px',
    borderBottom: `solid 1px ${theme.palette.border}`
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '34px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    lineHeight: 'normal',
    marginBottom:  '9px'
  },
  closeIcon: {
    width: '25px',
    height: 'auto',
    color: theme.palette.border,
    cursor: 'pointer',
  },
  semiheaderHeader: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 'normal',
  },
  regularText: {
    fontWeight: theme.typography.fontWeightRegular
  },
  extrasList: {
    borderBottom: `solid 1px ${theme.palette.border}`,
    overflowY: 'auto',
    maxHeight: '340px'
  },
  extraLine: {
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',

    '&:last-child': {
      marginBottom: '18px',
    }
  },
  extraTexts: {
    flex: 1,
  },
  extraHeader: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: '5px'
  },
  extraPriceText: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  extraCounts: {
    display: 'flex',
    alignItems: 'center'
  },
  countTxt: {
    padding: "0 25px",
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  countBtn: {
    cursor: 'pointer',
    display: 'flex',
    paddingTop: '2px',
    justifyContent: 'center',
    fontSize: '28px',
    alignItems: 'center',
    height: '26px',
    width: '26px',
    borderRadius: '26px',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,

    '&.inactive': {
      borderColor: theme.palette.border,
      color: theme.palette.border,
    }
  },
  buttonsBlock: {
    textAlign: 'right',
    marginTop: '35px'
  },
  btn: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    padding: '10px 60px',
    boxShadow: 'none',
    borderRadius: '21px'
  },
  cancelBtn: {
    padding: '10px 50px',
    color: theme.palette.common.black,
  },
  addBtn: {
    color: 'white',
    letterSpacing: '1.1px',
  }
}))

const ExtrasModal = ({ isOpen, extras, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {selectedExtras} = useSelector((state) => state.cars)
  const [extrasToShow, setExtrasToShow] = useState([])
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'

  const buildExtrasToShow = useCallback(() => {
    const tmpExtras = selectedExtras?.length ? selectedExtras : []
    const countsById = {}
    tmpExtras.forEach(e => {
      if (!e.uniqueId) return
      countsById[e.uniqueId] =  typeof (e.count) !== 'undefined' ? e.count : 0
    })

    const result =  (extras || []).map(e => {
      const {uniqueId} = e
      return {
        ...e,
        count: countsById?.[uniqueId] || 0
      }
    })

    setExtrasToShow(result)
  }, [extras, selectedExtras])

  const cancel = () => {
    buildExtrasToShow()
    onClose()
  }

  const save = () => {
    dispatch(setCarExtras(extrasToShow.filter(e => e.count > 0 && !!e.uniqueId)))
    onClose()
  }

  const changeCount = (id, applyCount = 1) => {
    const res = extrasToShow.map(e => {
      const {uniqueId, count, limit} = e
      const tmpLimit = parseInt(limit) > 0 ? parseInt(limit) : 1
      if ( uniqueId !== id ) return {...e}
      const currCount = parseInt(count || 0)
      let newCount = !isNaN(currCount) ? currCount + applyCount : applyCount
      if (newCount < 0) {
        newCount = 0
      } else if (newCount > tmpLimit) {
        newCount = tmpLimit
      }

      return {...e, count: newCount}
    })
    setExtrasToShow(res)
  }

  useEffect(() => {
    buildExtrasToShow()
  }, [buildExtrasToShow])

  return (
    <ModalHook
      minHeight={300}
      width={500}
      modalBoolean={isOpen}
      marginTop={250}
    >
      <Box className={classes.root}>
        <Box className={classes.headerBlock}>
          <Box className={classes.header}>
            <Box flex={1}>{t('add extras')} <span className={classes.regularText}>({t('optional').toLowerCase()})</span></Box>
            <CloseIcon onClick={cancel} className={classes.closeIcon}/>
          </Box>
          <Box className={classes.semiheaderHeader}> {t('cov extra text')}</Box>
        </Box>
        {!!extrasToShow?.length ?
          (
            <Fragment>
              <Box className={classes.extrasList}>
                {
                  extrasToShow.map(e => {
                    const limit = e?.limit || 1
                    return (
                      <Box className={classes.extraLine}>
                        <Box className={classes.extraTexts}>
                          <Box className={classes.extraHeader}>
                            {e.name}
                          </Box>
                          <Box className={classes.extraPriceText}>
                            {numberWithCommas(Math.round(e.price))} {currencyCode} {t('each per rental')}
                          </Box>
                        </Box>
                        <Box className={classes.extraCounts}>
                          <span
                            onClick={() => changeCount(e.uniqueId, -1)}
                            className={`${classes.countBtn} ${e.count <= 0 ? 'inactive' : ''}`}
                          >
                            &ndash;
                          </span>
                          <span className={classes.countTxt}>{e.count}</span>
                          <span
                            onClick={() => changeCount(e.uniqueId)}
                            className={`${classes.countBtn} ${e.count >= limit ? 'inactive' : ''}`}
                          >
                            +
                          </span>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
              <Box className={classes.buttonsBlock}>
                <Button onClick={cancel} className={`${classes.btn} ${classes.cancelBtn}`}>{t('cancel')}</Button>
                <Button
                  onClick={save}
                  variant={'contained'}
                  color={'primary'}
                  className={`${classes.btn} ${classes.addBtn}`}
                >{t('add extras')}</Button>
              </Box>
            </Fragment>
          ) : (
             <Box className={classes.noExtras}>{t('no extras')}</Box>
          )
        }
      </Box>
    </ModalHook>
  )
}

export default memo(ExtrasModal)
