import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import numberWCommas from '../../../../utils/numberWithCommas'
import { getPassengersText } from '../../../../utils/general'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    borderRadius: theme.spacing(10),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    padding: theme.spacing(1, 4),
    minWidth: '280px'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const TrainTotalPrice = ({ totalPrice, roundTrip }) => {
  const { t } = useTranslation()
  const { items } = useSelector((state) => state.checkout)
  const { currency } = useSelector((state) => state.auth)
  const trainItem = items.find(i => i.type === 'Train')
  const trainPassengers = trainItem?.passengers || []
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const price = numberWCommas(totalPrice)

  const continueWithoutHotel = () => history.push('/checkout')

  if (isMobile) {
    return (
      <Box mt={2}>
        <Button
          fullWidth
          disableElevation
          onClick={continueWithoutHotel}
          className={classes.btn}
          color="primary"
          variant="contained"
        >
          {t('detail without hotel btn')}
        </Button>
      </Box>
    )
  }

  return (
    <Box className={classes.center}>
      <Box>
        <Box pt={1} pl={1}>
          <Typography className={classes.bold} align="left" variant="h6">
            {currency} {price}
          </Typography>
        </Box>
        <Box pt={1} pl={1}>
          <Typography align="left">
            {roundTrip ? t('detail mob return') : t('detail one way')} ( {getPassengersText(trainPassengers)} )
          </Typography>
        </Box>
        <Box pt={2}>
          <Button
            disableElevation
            onClick={continueWithoutHotel}
            className={classes.btn}
            color="primary"
            variant="contained"
          >
            {t('detail without hotel btn')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(TrainTotalPrice)
