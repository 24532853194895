import React, { memo, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTranslation } from 'react-i18next'
import capitalizeOnlyFirstLetter from '../../../../utils/capitalizeOnlyFirstLetter'
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter'
import { useSelector } from 'react-redux'
import SelectTransferPassenger from '../../selectTransferPassenger'
import { Alert } from '@material-ui/lab'
import { getFlightbusLogoFile } from '../../../../utils/taxiHelpers'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(1),
  },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '10px',
  },
  chooseBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: '0.7px',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 3),
  },
  icon: {
    objectFit: 'contain',
    width: '80px',
  },
  dot: {
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '4px',
    marginRight: '4px',
  },
}))

const FlightBusCard = ({
  model,
  setRelevantItinerary,
  current,
  handleReset,
  removeable,
  removeTransfer,
  show,
  pagination = null,
  notification = null,
  onPassengerSelect = null,
  goToTransfer = null,
  touched = false,
}) => {
  const classes = useStyles()
  const bookShowData = show === undefined ? model.details : [model]
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)

  const renderSingleItem = (data, key) => {
    const product = data.product.name
    const price = Math.trunc(data?.price?.includingVat)
    const count =
      typeof data?.passenger === 'object' && !!data?.passenger?.length
        ? data.passenger.length
        : 1
    let pickUpDate = data.startNode.time
    if (pickUpDate) pickUpDate = pickUpDate.split('+')?.[0] || pickUpDate

    const buildAddress = (node) => {
      if (!node?.location) return ''
      let result = ''
      if (node.location.type === 'LOCATION') {
        result = !!node.name
          ? `${node.name || ''} ${node.number || ''} (${
              node.location.community
            })`
          : node.location.name
      } else {
        result = node.location.name
      }

      return result.replace(/^flygbuss\s/i, '')
    }

    const payload = {
      ...data,
      flightnumber: current.meta?.flightnumber,
      index: current?.meta?.index,
    }

    const handleSelect = () => {
      handleReset()
      setRelevantItinerary(payload)
    }

    const button = (
      <Grid item xs={6}>
        <Button
          disableElevation
          onClick={handleSelect}
          fullWidth
          color="primary"
          variant="contained"
          className={classes.chooseBtn}
        >
          {price} {currency} / {t('person')}
        </Button>
      </Grid>
    )

    const logo = (
      <img
        src={getFlightbusLogoFile(
          data.product.code,
          data.startNode.street?.location?.name,
          data.endNode.street?.location?.name
        )}
        className={classes.icon}
        alt={data.product.code}
      />
    )

    return (
      <Box
        key={key}
        className={removeable ? classes.container : ''}
        width={'100%'}
        p={2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <Box display="flex" flex="1">
            {logo}
            {!!removeable && !!pagination && (
              <Box
                paddingLeft={'5px'}
                display="flex"
                alignItems="center"
                flex="1"
              >
                {pagination}
              </Box>
            )}
          </Box>
          {removeable ? (
            <Box className={classes.forceRight}>
              <IconButton
                onClick={() =>
                  removeTransfer({
                    index: data.index,
                    uniqueKey: data.uniqueKey,
                  })
                }
              >
                <CancelIcon fontSize="small" color="disabled" />
              </IconButton>
            </Box>
          ) : (
            ''
          )}
        </Box>
        {!!notification && (
          <Box mb={2}>
            <Alert severity={notification.type}>{t(notification.text)}</Alert>
          </Box>
        )}
        <Box>
          <Box pb={0.5}>
            <Typography
              display="inline"
              gutterBottom
              className={classes.weight}
            >
              {t('search from')}
            </Typography>

            <Typography display="inline" gutterBottom>
              {buildAddress(data?.startNode?.street)}
            </Typography>
          </Box>
          <Box pb={0.5}>
            <Typography
              display="inline"
              gutterBottom
              className={classes.weight}
            >
              {t('search to')}
            </Typography>
            <Typography display="inline" gutterBottom>
              {buildAddress(data?.endNode?.street)}
            </Typography>
          </Box>
          {removeable ? (
            <Box pb={1}>
              <Typography gutterBottom>
                {capitalizeOnlyFirstLetter(
                  moment(pickUpDate).format('ddd D MMM')
                )}
                <span className={classes.dot} />
                {count} x {capitalizeFirstLetter(t('one way'))}
                <span className={classes.dot} /> {currency} {price * count}
              </Typography>
            </Box>
          ) : (
            <Box pb={1}>
              <Typography
                display="inline"
                gutterBottom
                className={classes.weight}
              >
                {t('date')}
              </Typography>
              <Typography display="inline" gutterBottom>
                <Moment format="D MMM">{pickUpDate}</Moment> -{' '}
                {t('valid any departure')}
              </Typography>
            </Box>
          )}

          {data.co2 && (
            <Box pb={1}>
              <Typography display="inline" gutterBottom>
                <span className={classes.weight}>{data.co2}</span>
                {t('carbon dioxide per passenger')}
              </Typography>
            </Box>
          )}
        </Box>
        {removeable ? (
          ''
        ) : (
          <Box mt={2}>
            <Grid container spacing={1}>
              {button}
            </Grid>
          </Box>
        )}
        {!!onPassengerSelect && (
          <SelectTransferPassenger
            parentModel={current}
            transfer={data}
            onSelect={onPassengerSelect}
            goToTransfer={goToTransfer}
            touched={touched}
          />
        )}
      </Box>
    )
  }

  const renderItems = () =>
    bookShowData.map((data, index) =>
      renderSingleItem(data, `render-item-${index}`)
    )

  return <>{renderItems()}</>
}

export default memo(FlightBusCard)
