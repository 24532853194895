import React, { Fragment } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Policy from '../../components/reusable/tags/policy'
import { Box, Grid } from '@material-ui/core'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SpeedIcon from '@material-ui/icons/Speed'
import CheckIcon from '@material-ui/icons/Check'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import numberWithCommas from '../../utils/numberWithCommas'
import { useTranslation } from 'react-i18next'
import { setSelectedCar } from '../../store/cars/carAction'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencyCodeBySign } from '../../utils/general'
import { useInView } from 'react-intersection-observer'
import { POLICY_RESULT } from '../../constants/policy'
import { Eco, LocalGasStation } from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
  mainRow: {
    minHeight: '100%',
  },
  bottomInfoRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '27px',
  },
  bottomInfoLeft: {
    flex: 1,
  },
  freeCancelIcon: {
    color: theme.palette.policyTextGreen,
    height: '22px',
    marginLeft: '10px',
  },
  bottomInfoLabelBlock: {
    fontSize: '15px',
    color: theme.palette.policyTextGreen,
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noImgBlock: {
    width: '100%',
    paddingTop: '56.25%',
    backgroundColor: theme.palette.dimGray,
    position: 'relative',
    borderTopLeftRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '8px',
    },
  },
  noImgInnerBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: '25px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
  singleCarImage: {
    width: '100%',
    height: 'auto',
    borderTopLeftRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '8px',
    },
  },
  companyLogoBlock: {
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0 10px 0',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  rightsideBlock: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.palette.border}`,
    },
  },
  carModelText: {
    fontSize: '21px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    lineHeight: 'normal',
    '& .muted': {
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  imageOuter: {
    padding: 0,
  },
  dataOuter: {
    padding: theme.spacing(2, 0),
    borderLeft: `1px solid ${theme.palette.border}`,
    paddingLeft: '27px',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      borderLeft: 'none',
    },
  },
  similarSizeRow: {
    marginTop: '16px',
  },
  policyBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  tariffDays: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '3px',

    '&:first-child': {
      marginTop: '15px',
    },
  },
  infoIcon: {
    width: '22px',
    marginRight: '8px',
    color: theme.palette.common.black,
  },
  infoText: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black,
  },
  locationsBlock: {
    marginTop: '27px',
  },
  locationRow: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black,
    lineHeight: 'normal',
    marginTop: '5px',

    '&:first-child': {
      marginTop: '0',
    },
  },

  engineBlock: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    color: theme.palette.policyTextGreen,
    backgroundColor: theme.palette.policyGreen,
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
  },
}))

const SingleCarsDataBlock = ({ car }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)
  const sDate = new moment(car?.startDate, 'YYYY-MM-DD HH:mm')
  const eDate = new moment(car?.endDate, 'YYYY-MM-DD HH:mm')
  const msInDay = 1000 * 60 * 60 * 24 //milliseconds in day
  const diff = eDate.diff(sDate)
  const tariffDays = Math.ceil(diff / msInDay)
  const picPlace =
    _.startCase(_.toLower(car.pickLocation.addressLine)) +
    ', ' +
    _.startCase(_.toLower(car.pickLocation.city))
  const dropPlace =
    _.startCase(_.toLower(car.dropLocation.addressLine)) +
    ', ' +
    _.startCase(_.toLower(car.dropLocation.city))
  const currencyCode = getCurrencyCodeBySign(currency)
  const isFreeCancellation =
    !car?.guaranteeRequired || car.guaranteeRequired === 'iata'
  const { co2 } = car
  const { fuel } = car.vehicle

  const mouseEnter = () => {
    dispatch(setSelectedCar(car))
  }

  const mouseLeave = () => {}

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px 0px 200px 0px',
  })

  return (
    <Fragment>
      <Grid
        ref={ref}
        key={'car-rate-inner-' + car.rateId}
        container
        className={classes.mainRow}
        spacing={0}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {inView && (
          <Fragment>
            <Grid
              key={'car-rate-inner-img-block' + car.rateId}
              className={classes.imageOuter}
              item
              xs={12}
              sm={5}
            >
              {!!car?.vehicle?.image && car.vehicle.image !== 'https://none' ? (
                <img
                  key={'car-rate-inner-img' + car.rateId}
                  src={car.vehicle.image}
                  className={classes.singleCarImage}
                />
              ) : (
                <Box
                  key={'car-rate-inner-no-img-block' + car.rateId}
                  className={classes.noImgBlock}
                >
                  <Box
                    key={'car-rate-inner-no-img' + car.rateId}
                    className={classes.noImgInnerBlock}
                  >
                    {t('no image')}
                  </Box>
                </Box>
              )}
              {!!car.carCompanyData.companyName && (
                <Box className={classes.companyLogoBlock}>
                  {car.carCompanyData.companyName}
                </Box>
              )}
              {fuel === 'electric' && (
                <Box style={{ textAlign: 'center' }}>
                  <Box className={classes.engineBlock}>{t('electric')}</Box>
                </Box>
              )}
              {fuel === 'hybrid' && (
                <Box style={{ textAlign: 'center' }}>
                  <Box className={classes.engineBlock}>{t('hybrid')}</Box>
                </Box>
              )}
            </Grid>
            <Grid
              key={'car-rate-inner-content-block' + car.rateId}
              className={classes.dataOuter}
              item
              xs={12}
              sm={7}
            >
              <Grid key={'car-rate-inner-content-top' + car.rateId} container>
                <Grid
                  key={'car-rate-inner-content-left' + car.rateId}
                  item
                  xs={7}
                >
                  <Box
                    key={'car-rate-inner-content-header' + car.rateId}
                    className={classes.carModelText}
                  >
                    {car?.vehicle?.model || t('unknown car model')}
                    <Box
                      key={'car-rate-inner-content-header-muted' + car.rateId}
                      className={`muted ${classes.similarSizeRow}`}
                    >
                      {t('or similar sized car')}
                    </Box>
                  </Box>
                  <Box width={'100%'}>
                    {!!car?.vehicle?.info?.passengersCount?.value && (
                      <Box
                        key={'car-rate-inner-info-row-seats' + car.rateId}
                        className={classes.infoRow}
                      >
                        <PermIdentityIcon className={classes.infoIcon} />
                        <span
                          key={'car-rate-inner-info-row-seats-txt' + car.rateId}
                          className={classes.infoText}
                        >
                          {car?.vehicle?.info?.passengersCount?.value}{' '}
                          {t('seats')}
                        </span>
                      </Box>
                    )}
                    {!!car?.vehicle?.freeMileage && (
                      <Box
                        key={'car-rate-inner-info-row-mileage' + car.rateId}
                        className={classes.infoRow}
                      >
                        <SpeedIcon className={classes.infoIcon} />
                        <span
                          key={
                            'car-rate-inner-info-row-mileage-txt' + car.rateId
                          }
                          className={classes.infoText}
                        >
                          {car?.vehicle?.freeMileage === 'UNL'
                            ? t('unlimited mileage')
                            : car?.vehicle?.freeMileage}
                        </span>
                      </Box>
                    )}
                    {!!car?.vehicle?.transmission && (
                      <Box
                        key={
                          'car-rate-inner-info-row-transmission' + car.rateId
                        }
                        className={classes.infoRow}
                      >
                        <SettingsInputComponentIcon
                          className={classes.infoIcon}
                        />
                        <span
                          key={
                            'car-rate-inner-info-row-transmission-txt' +
                            car.rateId
                          }
                          className={classes.infoText}
                        >
                          {car?.vehicle?.transmission === 'A'
                            ? t('automatic')
                            : t('manual')}
                        </span>
                      </Box>
                    )}
                    {fuel && (
                      <Box className={classes.infoRow}>
                        <LocalGasStation className={classes.infoIcon} />
                        <span className={classes.infoText}>{t(fuel)}</span>
                      </Box>
                    )}

                    {co2 && (
                      <Box className={classes.infoRow}>
                        <Eco className={classes.infoIcon} />
                        <span className={classes.infoText}>
                          {co2 + ' ' + t('carbon dioxide per km')}
                        </span>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid
                  key={'car-rate-inner-right-block' + car.rateId}
                  className={classes.rightsideBlock}
                  item
                  xs={5}
                >
                  <Box
                    key={'car-rate-inner-right-price' + car.rateId}
                    textAlign={'right'}
                    className={classes.carModelText}
                  >
                    {numberWithCommas(Math.round(car?.estimatedTotal || 0))}
                    {currencyCode}
                  </Box>

                  <Box
                    key={'car-rate-inner-right-policy' + car.rateId}
                    className={classes.policyBlock}
                  >
                    <Policy
                      key={'car-rate-inner-right-policy-inner' + car.rateId}
                      corporate={car.isCorporateFare}
                      deal={car.isDealFare}
                      policy={POLICY_RESULT.PASS}
                    />
                  </Box>

                  <Box
                    key={'car-rate-inner-right-days' + car.rateId}
                    className={classes.tariffDays}
                  >
                    {t('car tariff days', { num: tariffDays })}
                  </Box>
                </Grid>
              </Grid>
              <Grid key={'car-rate-inner-bottom' + car.rateId} item xs={12}>
                <Box
                  key={'car-rate-inner-bottom-loc' + car.rateId}
                  className={classes.locationsBlock}
                >
                  <Box
                    key={'car-rate-inner-bottom-loc-from' + car.rateId}
                    className={classes.locationRow}
                  >
                    {picPlace}
                  </Box>
                  {picPlace !== dropPlace && (
                    <Box
                      key={'car-rate-inner-bottom-loc-to' + car.rateId}
                      className={classes.locationRow}
                    >
                      {dropPlace}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid key={'car-buttom-info-row-inner' + car.rateId} item xs={12}>
                <Box
                  key={'car-bottom-info-row' + car.rateId}
                  className={classes.bottomInfoRow}
                >
                  <Box
                    key={'car-bottom-info-left' + car.rateId}
                    className={classes.bottomInfoLeft}
                  ></Box>
                  {isFreeCancellation && (
                    <Box
                      key={'car-bottom-info-right' + car.rateId}
                      className={classes.bottomInfoLabelBlock}
                    >
                      {t('free cancellation')}{' '}
                      <CheckIcon className={classes.freeCancelIcon} />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}

export default SingleCarsDataBlock
