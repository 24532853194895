import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { useTranslation } from 'react-i18next'
import { setHotelMaxPriceFilters } from '../../../store/hotels/hotelsAction'
import { useSliderStyles } from '../../flights/filters/sliderStyles'
import { HotelFilterContext } from './hotelFilterDrawer'
import { getCurrencyCodeBySign } from '../../../utils/general'

const MaxPriceSlider = () => {
  const [maxPrice, setMaxPrice] = useState(0)
  const reset = useContext(HotelFilterContext)
  const search = useSelector((state) => state.hotels.search)
  const priceFilter = useSelector((state) => state.hotels.filters.maxPrice)
  const dispatch = useDispatch()
  const classes = useSliderStyles()
  const { t } = useTranslation()
  const { currency } = useSelector(state => state.auth)
  const currencyCode = getCurrencyCodeBySign(currency)

  const maxPriceInArray = React.useCallback(() => _.maxBy(search, (el) => el.pricePerNight), [search])

  useEffect(() => {
    if(priceFilter === 10000000000 && maxPriceInArray()?.pricePerNight) {
      setMaxPrice(Number(maxPriceInArray()?.pricePerNight))
      dispatch(setHotelMaxPriceFilters(Number(maxPriceInArray()?.pricePerNight)))
    } else {
      setMaxPrice(priceFilter)
    }
    // eslint-disable-next-line
  }, [reset])

  const onChange = (e, value) => {
    setMaxPrice(value)
  }

  const applyChange = (e, value) => {
    dispatch(setHotelMaxPriceFilters(value))
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>
          {t('hotel results max price')}
        </Typography>
      </Box>
      <Slider
        defaultValue={Number(maxPriceInArray()?.pricePerNight && maxPriceInArray()?.pricePerNight) || 0}
        value={maxPrice}
        onChange={onChange}
        onChangeCommitted={applyChange}
        valueLabelDisplay="on"
        step={50}
        valueLabelFormat={(val) => `${val}${currencyCode}`}
        color="primary"
        max={Number((maxPriceInArray() && maxPriceInArray()?.pricePerNight)) || 1}
        min={50}
        classes={{root: classes.root}}
      />
    </>
  )
}

export default MaxPriceSlider
