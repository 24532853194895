import React, { memo, useEffect } from 'react'
import { Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import numberWithCommnas from '../../../utils/numberWithCommas'
import { calculatePrice } from '../../../utils/flightGeneralFunctions'
import { useTranslation } from 'react-i18next'
import { setCurrentFlightPrice } from '../../../store/flights/searchAction'
import { getPassengersText } from '../../../utils/general'

const useStyles = makeStyles((theme) => ({
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 10px',
      marginLeft: 0,
      fontWeight: 500,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  link: {
    textDecoration: 'none',
    textTransform: 'none',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const TotalPriceDetails = ({ loading = false }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const flightsData = useSelector((state) => state.flights)
  const flightDetails = useSelector((state) => state.flightDetails)
  const { currency } = useSelector((state) => state.auth)
  const { returning, selectedMulti, passengers } = flightsData
  const { passengerSeats } = flightDetails
  const { t } = useTranslation()

  const prices = calculatePrice(flightDetails, flightsData)

  // Init Price
  // Listen to changes
  // Listen to seats state

  const normalTitle = !returning ? t('detail one way') : t('detail mob return')
  const title =
    Object.values(selectedMulti).length > 0
      ? t('flight multistop')
      : normalTitle

  const numberOfSeats = passengerSeats.length
  const totaltotalPrice = parseInt(prices.total)

  useEffect(() => {
    dispatch(setCurrentFlightPrice(totaltotalPrice))
  }, [dispatch, totaltotalPrice])

  return (
    <Box mt={0} p={2} pb={1} pt={3}>
      <Box pb={1} className={classes.spaceBetween}>
        <Typography>
          {title} ({getPassengersText(passengers, true)})
        </Typography>
        <Typography>
          {currency}{' '}
          {numberWithCommnas(
            parseInt(!loading ? prices.fare : prices.initTotal)
          )}
        </Typography>
      </Box>
      {!loading && passengerSeats.length ? (
        <Box className={classes.spaceBetween} pb={1}>
          <Typography>
            {numberOfSeats} x {t('detail reservations')}
          </Typography>
          <Typography>
            {currency} {prices.seats}
          </Typography>
        </Box>
      ) : null}
      {!loading && !!prices.bagsByType?.carryOn && (
        <Box className={classes.spaceBetween} pb={1}>
          <Typography>{t('carry-on baggage')}</Typography>
          <Typography>
            {currency} {numberWithCommnas(parseInt(prices.bagsByType.carryOn))}
          </Typography>
        </Box>
      )}
      {!loading && !!prices.bagsByType?.general && (
        <Box className={classes.spaceBetween} pb={1}>
          <Typography>{t('detail bagage')}</Typography>
          <Typography>
            {currency} {numberWithCommnas(parseInt(prices.bagsByType.general))}
          </Typography>
        </Box>
      )}
      <Divider />
      <Box pt={1} pb={1} className={classes.spaceBetween}>
        <Typography className={classes.weight}>{t('detail total')}</Typography>
        <Box className={classes.align}>
          <Typography className={classes.weight} variant="body2">
            {currency}
          </Typography>
          <Typography
            className={classes.weight}
            variant="h6"
            style={{ marginRight: 0 }}
          >
            {loading &&
              prices.initTotal &&
              numberWithCommnas(parseInt(prices.initTotal))}
            {!loading && prices.total && numberWithCommnas(totaltotalPrice)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(TotalPriceDetails)
