import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import numberWithCommas from '../../../../utils/numberWithCommas'
import { getPassengersText } from '../../../../utils/general'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  center: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: '100%',
  },

  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  btn: {
    borderRadius: theme.spacing(10),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    padding: theme.spacing(1, 2),
    minWidth: '280px',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const FlightTotalPrice = ({ roundTrip, totalPrice }) => {
  const { t } = useTranslation()
  const { items } = useSelector((state) => state.checkout)
  const { currency } = useSelector((state) => state.auth)
  const flightItem = items.find((i) => i.type === 'Flight')
  const flightPassengers = flightItem?.passengers || []
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const continueWithoutHotel = () => history.push('/checkout')

  if (isDesktop) {
    return (
      <Box className={classes.center}>
        <Box>
          <Typography className={classes.weight} variant="h6">
            {currency} {numberWithCommas(totalPrice)}
          </Typography>
          <Typography>
            {roundTrip ? t('detail mob return') : t('detail one way')} ({' '}
            {getPassengersText(flightPassengers, false)} )
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            disableElevation
            className={classes.btn}
            color="primary"
            variant="contained"
            onClick={continueWithoutHotel}
          >
            {t('detail without hotel btn')}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box mt={2}>
      <Button
        fullWidth
        disableElevation
        className={classes.btn}
        color="primary"
        variant="contained"
        onClick={continueWithoutHotel}
      >
        {t('detail without hotel btn')}
      </Button>
    </Box>
  )
}
export default memo(FlightTotalPrice)
